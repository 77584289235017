import React from 'react';

import { IoIosClose } from "react-icons/io";
function Footer() {

    return (
        <>
            <footer className='w-full bg-verde1 flex flex-col items-center rounded-b-2xl'>
                <img src='mozak.png' alt='Mozak' className='h-[20px] my-[20px] lg:h-[15px] lg:my-[15px] 2xl:h-[15px] w-auto 2xl:my-[15px] object-contain brightness-0 invert' />
                <span className='cursor-pointer underline underline-offset-2  text-sm text-white' onClick={() => document.getElementById('modal')?.setAttribute('data-modal', 'active')}>Saiba mais sobre a mozak</span>
                <span className='text-[.6rem] text-white mt-[5px] mb-[20px] lg:mb-[15px] 2xl:mb-[15px]'>CRECI RJ-008658/O</span>
            </footer>
        </>
    );
}

export default Footer;