function Banner() {

    return (
        <div id='banner' className='fixed top-0 w-full z-20 drop-shadow-md'>
            <img src="./mozak.png" alt='BS"D' className="absolute top-10 left-10 drop-shadow-lg brightness-0 invert w-[80px]" />
            <img src="./bsd.png" alt='BS"D' className="absolute top-10 right-10 drop-shadow-lg brightness-0 invert w-[40px]" />
        </div>
    );
}

export default Banner;