import React from 'react';

interface IPoliticas {
    setPoliticas: React.Dispatch<React.SetStateAction<boolean>>,
    politicas: boolean
}

function Politicas({ setPoliticas, politicas }: IPoliticas) {
    return (
        <section id='politicas' className={`fixed top-0 left-0 w-full h-full justify-center items-center backdrop-blur-md z-[999] ${politicas ? 'flex opacity-100' : 'hidden opacity-0'}`}>
            <div className='absolute top-0 left-0 h-full w-full z-[1] cursor-pointer' onClick={() => setPoliticas(!politicas)}></div>
            <div className='bg-white container overflow-x-hidden overflow-y-scroll h-[90%] px-10 py-10 relative z-[2]'>
                <button
                    className='text-gray-800 text-3xl absolute right-5 top-5'
                    onClick={() => setPoliticas(!politicas)}
                >
                    &#x2716;
                </button>
                <p className='text-title'>
                    POLÍTICA DE PRIVACIDADE
                </p>
                <p>
                    Este site é mantido e operado por:
                    <br />
                    MOZAK ENGENHARIA LTDA.
                    <br />
                    CNPJ: 01.432.484/0001-00
                </p>
                <p>
                    Nós coletamos e utilizamos alguns dados pessoais que pertencem àqueles que
                    utilizam nosso site. Ao fazê-lo, agimos na qualidade de controlador desses
                    dados e estamos sujeitos às disposições da Lei Federal n. 13.709/2018 (Lei
                    Geral de Proteção de Dados Pessoais - LGPD).
                </p>
                <p>
                    Nós cuidamos da proteção de seus dados pessoais e, por isso,
                    disponibilizamos esta política de privacidade, que contém informações
                    importantes sobre:
                </p>
                <p>
                    - Quem deve utilizar nosso site
                    <br />
                    - Quais dados coletamos e o que fazemos com eles;
                    <br />
                    - Seus direitos em relação aos seus dados pessoais; e
                    <br />
                    - Como entrar em contato conosco.
                </p>
                <h1>
                    1. Dados que coletamos e motivos da coleta
                </h1>
                <p>
                    <br />
                    Nosso site coleta e utiliza alguns dados pessoais de nossos usuários, de
                    acordo com o disposto nesta seção.
                </p>
                <p>
                    1. Dados pessoais fornecidos expressamente pelo usuário
                </p>
                <p>
                    <br />
                    Nós coletamos os seguintes dados pessoais que nossos usuários nos fornecem
                    expressamente ao utilizar nosso site:
                </p>
                <p>
                    Formulários de Contato do Site:
                    <br />
                    - Nome;
                    <br />
                    - E-mail;
                    <br />
                    - DDD + Telefone;
                    <br />
                    - DDD + Celular;
                    <br />
                    - Endereço (Do imóvel ofertado);
                    <br />
                    - Detalhes e características do imóvel (do imóvel ofertado);
                </p>
                <p>
                    A coleta destes dados ocorre nos seguintes momentos:
                </p>
                <p>
                    - Solicitação de contato através do formulário de "Fale Conosco / Contato";
                    <br />
                    - Solicitação de contato através do formulário de Empreendimento "Quero
                    Mais Informações";
                    <br />
                    - Solicitação de contato através do formulário de "WhatsApp" no site e em
                    Landing Pages;
                    <br />
                    - Solicitação de contato através do formulário "Venda seu Terreno";
                    <br />
                    - Solicitação de contato através do sistema de "Atendimento Online"
                    <br />
                    - Solicitação de cadastro através do formulário de "Receba Novidades"
                </p>
                <p>
                    Os dados fornecidos por nossos usuários são coletados com as seguintes
                    finalidades:
                </p>
                <p>
                    - Para que o usuário possa receber mais informações sobre um ou mais
                    imóveis específicos e também receber novas ofertas de novas unidades de
                    imóveis.
                    <br />
                    - Para o esclarecimento de alguma dúvida, fazer um elogio ou reclamação.
                </p>
                <p>
                    2. Dados pessoais obtidos de outras formas
                </p>
                <p>
                    Nós coletamos os seguintes dados pessoais de nossos usuários:
                </p>
                <p>
                    - Endereço IP
                    <br />
                    - Dados de Geolocalização
                    <br />
                    - Histórico de navegação e fluxo de acesso ao site.
                </p>
                <p>
                    A coleta destes dados ocorre nos seguintes momentos:
                </p>
                <p>
                    - Ao acessar o site e aceitar os Cookies;
                    <br />
                    - Ao navegar nas páginas do site;
                    <br />
                    - Ao salvar imóveis em seus Favoritos;
                </p>
                <p>
                    Estes dados são coletados com as seguintes finalidades:
                </p>
                <p>
                    - Personalizar a experiência do usuário;
                    <br />
                    - Parametrizar opções de ofertas de imóveis;
                    <br />
                    - Mapear o uso do site;
                    <br />
                    - Analisar a aceitação do conteúdo ofertado.
                </p>
                <p>
                    3. Dados sensíveis
                </p>
                <p>
                    Não serão coletados dados sensíveis de nossos usuários, assim entendidos
                    aqueles definidos nos arts. 11 e seguintes da Lei de Proteção de Dados
                    Pessoais. Assim, não haverá coleta de dados sobre origem racial ou étnica,
                    convicção religiosa, opinião política, filiação a sindicato ou a
                    organização de caráter religioso, filosófico ou político, dado referente à
                    saúde ou à vida sexual, dado genético ou biométrico, quando vinculado a uma
                    pessoa natural.
                </p>
                <p>
                    4. Cookies
                </p>
                <p>
                    Cookies são pequenos arquivos de texto baixados automaticamente em seu
                    dispositivo quando você acessa e navega por um site. Eles servem,
                    basicamente, para seja possível identificar dispositivos, atividades e
                    preferências de usuários.
                </p>
                <p>
                    Os cookies não permitem que qualquer arquivo ou informação sejam extraídos
                    do disco rígido do usuário, não sendo possível, ainda, que, por meio deles,
                    se tenha acesso a informações pessoais que não tenham partido do usuário ou
                    da forma como utiliza os recursos do site.
                </p>
                <p>
                    a. Cookies do site
                </p>
                <p>
                    Os cookies do site são aqueles enviados ao computador ou dispositivo do
                    usuário e administrador exclusivamente pelo site.
                </p>
                <p>
                    As informações coletadas por meio destes cookies são utilizadas para
                    melhorar e personalizar a experiência do usuário, sendo que alguns cookies
                    podem, por exemplo, ser utilizados para lembrar as preferências e escolhas
                    do usuário, bem como para o oferecimento de conteúdo personalizado.
                </p>
                <p>
                    b. Cookies de terceiros
                </p>
                <p>
                    <br />
                    Alguns de nossos parceiros podem configurar cookies nos dispositivos dos
                    usuários que acessam nosso site.
                </p>
                <p>
                    Estes cookies, em geral, visam possibilitar que nossos parceiros possam
                    oferecer seu conteúdo e seus serviços ao usuário que acessa nosso site de
                    forma personalizada, por meio da obtenção de dados de navegação extraídos a
                    partir de sua interação com o site.
                </p>
                <p>
                    O usuário poderá obter mais informações sobre os cookies de terceiro e
                    sobre a forma como os dados obtidos a partir dele são tratados, além de ter
                    acesso à descrição dos cookies utilizados e de suas características,
                    acessando o seguinte link:
                </p>
                <p>
                    - Google Ads e Analytics:
                    <br />
                    https://static.googleusercontent.com/media/www.google.com/pt-BR//intl/pt-BR/policies/privacy/google_privacy_policy_pt-BR.pdf
                    <br />
                    <br />
                    - Facebook:
                    <br />
                    https://www.facebook.com/policy.php
                    <br />
                    <br />
                    - Instagram:
                    <br />
                    https://www.instagram.com/terms/accept/?hl=pt-br
                    https://www.facebook.com/help/instagram/519522125107875
                    <br />
                    <br />
                    - ANAPRO:
                    <br />
                    https://materiais.anapro.com.br/politica-de-privacidade
                </p>
                <br />
                <p>
                    As entidades encarregadas da coleta dos cookies poderão ceder as
                    informações obtidas a terceiros.
                </p>
                <p>
                    c. Gestão de cookies
                </p>
                <p>
                    O usuário poderá se opor à utilização de cookies pelo site, bastando que os
                    desative no momento em que começa a utilizar o serviço, seguindo as
                    seguintes instruções:
                </p>
                <p>
                    Assim que entrar no site, o usuário terá a opção de "Aceitar" a utilização
                    de cookies, bastando que selecione a opção de “Concordar e Continuar” na
                    caixa de diálogo (popup) carregada automaticamente assim que nossa página é
                    acessada. Caso não concorde com na coleta de cookies, feche o
                    navegador/site.
                </p>
                <p>
                    A desativação dos cookies, no entanto, não será possível, uma vez que eles
                    são essenciais para que o site funcione corretamente.
                </p>
                <p>
                    Além disso, a desativação dos cookies que podem ser desabilitados poderá
                    prejudicar a experiência do usuário, uma vez que informações utilizadas
                    para personalizá-la deixarão de ser utilizadas.
                </p>
                <p>
                    5. Coleta de dados não previstos expressamente
                </p>
                <p>
                    Eventualmente, outros tipos de dados não previstos expressamente nesta
                    Política de Privacidade poderão ser coletados, desde que sejam fornecidos
                    com o consentimento do usuário, ou, ainda, que a coleta seja permitida com
                    fundamento em outra base legal prevista em lei.
                </p>
                <p>
                    Em qualquer caso, a coleta de dados e as atividades de tratamento dela
                    decorrentes serão informadas aos usuários do site.
                </p>
                <h1>
                    2. Compartilhamento de dados pessoais com terceiros
                </h1>
                <p>
                    Nós não compartilhamos seus dados pessoais com terceiros. Apesar disso, é
                    possível que o façamos para cumprir alguma determinação legal ou
                    regulatória, ou, ainda, para cumprir alguma ordem expedida por autoridade
                    pública. Apenas os nossos corretores, da equipe de vendas, terão acesso aos
                    dados para contato e envio das informações solicitadas pelo próprio
                    usuário.
                </p>
                <h1>
                    3. Por quanto tempo seus dados pessoais serão armazenados
                </h1>
                <p>
                    Os dados pessoais que coletamos serão armazenados e utilizados pelos
                    seguintes períodos de tempo:
                </p>
                <p>
                    - Os dados coletados em formulários de interesse em imóveis, permanecerão
                    armazenados sob nossos cuidados e em segurança até o momento que o usuário
                    solicitar ou o mesmo fizer a remoção, pois entenderemos que, enquanto não
                    solicitar a sua remoção, o mesmo ainda terá o interesse de receber mais
                    informações sobre imóveis, para os quais ele solicitou e outros que
                    julgamos como fazer parte de seu interesse.
                </p>
                <p>
                    Os períodos informados não são superiores ao estritamente necessário,
                    atendendo às finalidades e às justificativas legais para o tratamento dos
                    dados.
                </p>
                <p>
                    Vale dizer que, se houver alguma justificativa legal ou regulatória, os
                    dados poderão continuar armazenados ainda que a finalidade para a qual
                    foram coletados ou tenham sido tratados tenha se exaurido.
                </p>
                <p>
                    Uma vez finalizado o tratamento, observadas as disposições desta seção, os
                    dados são apagados ou anonimizados.
                </p>
                <h1>
                    4. Bases legais para o tratamento de dados pessoais
                </h1>
                <p>
                    <br />
                    Uma base legal para o tratamento de dados pessoais nada mais é que um
                    fundamento jurídico, previsto em lei, que justifica o justifica. Assim,
                    cada operação de tratamento de dados pessoais precisa ter uma base legal a
                    ela correspondente.
                </p>
                <p>
                    <br />
                    Nós tratamos os dados pessoais de nossos usuários nas seguintes hipóteses:
                </p>
                <p>
                    - mediante o consentimento do titular dos dados pessoais
                </p>
                <p>
                    - quando necessário para atender aos interesses legítimos do controlador ou
                    de terceiro
                </p>
                <p>
                    1. Consentimento
                </p>
                <p>
                    Determinadas operações de tratamento de dados pessoais realizadas em nosso
                    site dependerão da prévia concordância do usuário, que deverá manifestá-la
                    de forma livre, informada e inequívoca.
                </p>
                <p>
                    O usuário poderá revogar seu consentimento a qualquer momento, sendo que,
                    não havendo hipótese legal que permita ou que demande o armazenamento dos
                    dados, os dados fornecidos mediante consentimento serão excluídos.
                </p>
                <p>
                    Além disso, se desejar, o usuário poderá não concordar com alguma operação
                    de tratamento de dados pessoais baseada no consentimento. Nestes casos,
                    porém, é possível que não possa utilizar alguma funcionalidade do site que
                    dependa daquela operação. As consequências da falta de consentimento para
                    uma atividade específica são informadas previamente ao tratamento.
                </p>
                <p>
                    2. Legítimo interesse
                </p>
                <p>
                    Para determinadas operações de tratamento de dados pessoais, nos baseamos
                    exclusivamente em nosso interesse legítimo. Para saber mais sobre em quais
                    casos, especificamente, nos valemos desta base legal, ou para obter mais
                    informações sobre os testes que fazemos para termos certeza de que podemos
                    utilizá-la, entre em contato com nosso Encarregado de Proteção de Dados
                    Pessoais por algum dos canais informados nesta Política de Privacidade, na
                    seção "Como entrar em contato conosco".
                </p>
                <h1>
                    5. Direitos do usuário
                </h1>
                <p>
                    O usuário do site possui os seguintes direitos, conferidos pela Lei de
                    Proteção de Dados Pessoais:
                </p>
                <p>
                    - confirmação da existência de tratamento;
                    <br />
                    - acesso aos dados;
                    <br />
                    - correção de dados incompletos, inexatos ou desatualizados;
                    <br />
                    - anonimização, bloqueio ou eliminação de dados desnecessários, excessivos
                    ou tratados em desconformidade com o disposto na lei;
                    <br />
                    - portabilidade dos dados a outro fornecedor de serviço ou produto,
                    mediante requisição expressa, de acordo com a regulamentação da autoridade
                    nacional, observados os segredos comercial e industrial;
                    <br />
                    - eliminação dos dados pessoais tratados com o consentimento do titular,
                    exceto nos casos previstos em lei;
                    <br />
                    - informação das entidades públicas e privadas com as quais o controlador
                    realizou uso compartilhado de dados;
                    <br />
                    - informação sobre a possibilidade de não fornecer consentimento e sobre as
                    consequências da negativa;
                    <br />
                    - revogação do consentimento.
                </p>
                <p>
                    É importante destacar que, nos termos da LGPD, não existe um direito de
                    eliminação de dados tratados com fundamento em bases legais distintas do
                    consentimento, a menos que os dados seja desnecessários, excessivos ou
                    tratados em desconformidade com o previsto na lei.
                </p>
                <p>
                    1. Como o titular pode exercer seus direitos
                </p>
                <p>
                    Os titulares de dados pessoais tratados por nós poderão exercer seus
                    direitos por meio de pedido de acesso aos dados através do e-mail
                    protecaodedados@mozak.com.br. Alternativamente, se desejar, o titular
                    poderá enviar um e-mail ou uma correspondência ao nosso Encarregado de
                    Proteção de Dados Pessoais. As informações necessárias para isso estão na
                    seção "Como entrar em contato conosco" desta Política de Privacidade.
                </p>
                <p>
                    Os titulares de dados pessoais tratados por nós poderão exercer seus
                    direitos a partir do envio de mensagem ao nosso Encarregado de Proteção de
                    Dados Pessoais, seja por e-mail ou por correspondência. As informações
                    necessárias para isso estão na seção "Como entrar em contato conosco" desta
                    Política de Privacidade.
                </p>
                <p>
                    Para garantir que o usuário que pretende exercer seus direitos é, de fato,
                    o titular dos dados pessoais objeto da requisição, poderemos solicitar
                    documentos ou outras informações que possam auxiliar em sua correta
                    identificação, a fim de resguardar nossos direitos e os direitos de
                    terceiros. Isto somente será feito, porém, se for absolutamente necessário,
                    e o requerente receberá todas as informações relacionadas.
                </p>
                <h1>
                    6. Medidas de segurança no tratamento de dados pessoais
                </h1>
                <p>
                    <br />
                    Empregamos medidas técnicas e organizativas aptas a proteger os dados
                    pessoais de acessos não autorizados e de situações de destruição, perda,
                    extravio ou alteração desses dados.
                </p>
                <p>
                    As medidas que utilizamos levam em consideração a natureza dos dados, o
                    contexto e a finalidade do tratamento, os riscos que uma eventual violação
                    geraria para os direitos e liberdades do usuário, e os padrões atualmente
                    empregados no mercado por empresas semelhantes à nossa.
                </p>
                <p>
                    Entre as medidas de segurança adotadas por nós, destacamos as seguintes:
                </p>
                <p>
                    - Armazenamento de senhas utilizando hashes criptográficos;
                    <br />
                    - restrições de acessos a bancos de dados;
                    <br />
                    - monitoramento de acesso físicos a servidores;
                    <br />
                    - Segurança de ataques de acordo com as políticas da Amazon aws.
                </p>
                <p>
                    Ainda que adote tudo o que está ao seu alcance para evitar incidentes de
                    segurança, é possível que ocorra algum problema motivado exclusivamente por
                    um terceiro - como em caso de ataques de hackers ou crackers ou, ainda, em
                    caso de culpa exclusiva do usuário, que ocorre, por exemplo, quando ele
                    mesmo transfere seus dados a terceiro. Assim, embora sejamos, em geral,
                    responsáveis pelos dados pessoais que tratamos, nos eximimos de
                    responsabilidade caso ocorra uma situação excepcional como essas, sobre as
                    quais não temos nenhum tipo de controle.
                </p>
                <p>
                    De qualquer forma, caso ocorra qualquer tipo de incidente de segurança que
                    possa gerar risco ou dano relevante para qualquer de nossos usuários,
                    comunicaremos os afetados e a Autoridade Nacional de Proteção de Dados
                    acerca do ocorrido, em conformidade com o disposto na Lei Geral de Proteção
                    de Dados.
                </p>
                <h1>
                    7. Reclamação a uma autoridade de controle
                </h1>
                <p>
                    Sem prejuízo de qualquer outra via de recurso administrativo ou judicial,
                    os titulares de dados pessoais que se sentirem, de qualquer forma, lesados,
                    podem apresentar reclamação à Autoridade Nacional de Proteção de Dados.
                </p>
                <h1>
                    8. Alterações nesta política
                </h1>
                <p>
                    <br />
                    A presente versão desta Política de Privacidade foi atualizada pela última
                    vez em: 12/07/2021.
                </p>
                <p>
                    Nos reservamos o direito de modificar, a qualquer momento, as presentes
                    normas, especialmente para adaptá-las às eventuais alterações feitas em
                    nosso site, seja pela disponibilização de novas funcionalidades, seja pela
                    supressão ou modificação daquelas já existentes.
                </p>
                <p>
                    Sempre que houver uma modificação, nossos usuários serão notificados acerca
                    da mudança.
                </p>
                <h1>
                    9. Como entrar em contato conosco
                </h1>
                <p>
                    Para esclarecer quaisquer dúvidas sobre esta Política de Privacidade ou
                    sobre os dados pessoais que tratamos, entre em contato com nosso
                    Encarregado de Proteção de Dados Pessoais, por algum dos canais mencionados
                    abaixo:
                </p>
                <p>
                    E-mail: protecaodedados@mozak.com.br
                </p>
                <p>
                    Endereço postal: Av Ataulfo de Paiva, 391- sala 607 - Leblon
                    <br />
                    Rio de Janeiro – Brasil
                    <br />
                    CEP: 22.440-032
                </p>
                <p>
                    Telefones: (21) 3079-9619 / (21) 2523-9671
                </p>
            </div>
        </section>
    );
}

export default Politicas;