import React, { lazy, Suspense } from 'react';

const ReactPlayer = lazy(()=> import('react-player'))

function Video() {
    return (

        <Suspense>
        <div className='absolute top-0 left-0 z-0 w-full h-full'>
            <ReactPlayer
                    url='./VideoBG.mp4'
                    width={'100%'}
                    height={'100%'}
                    className='[&>video]:object-cover [&>video]:h-full [&>video]:w-full'
                playing
            playsinline
                    muted
                    loop
            >

            </ReactPlayer>
        </div>
        </Suspense>
    );
}

export default Video;