import gsap from 'gsap';
import './App.css';
import Content from './components/Content';

import { useGSAP } from '@gsap/react';
import Banner from './components/Banner';
import Video from './components/Video';

function App() {

  useGSAP(() => {
    const tl = gsap.timeline()



    tl.fromTo('#home', {
      opacity: 0
    }, {
      opacity: 1,
      delay: .5
    }).fromTo('#content > #text > *', {
      x: -50,
      opacity: 0
    }, {
      x: 0,
      opacity: 1,
      stagger: 0.3,
      duration: 1
    }).fromTo('#content > #form', {
      y: 30,
      opacity: 0
    }, {
      y: 0,
      opacity: 1,
      duration: 1,
      ease: 'power2.out'
    }, '-=.3')
  })

  return (
    <section id='home' className='w-full overflow-hidden h-screen flex md:flex-row flex-col-reverse bg-home bg-cover bg-center relative'>
      <div className='absolute top-0 left-0 bg-overflow w-full h-full z-10'>
      </div>
      <Video />
      <Content />
      <Banner />
    </section>
  );
}

export default App;
