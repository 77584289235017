import React from 'react';

function ModalMozak() {
    return (
        <div className="bg-white w-11/12 md:w-3/4 h-3/4 overflow-y-auto z-20">
            <div className="w-full h-full flex flex-col justify-between">
                <header className="flex flex-col items-center justify-center w-full h-[320px] md:h-[572px] relative">
                    <img src="./modal/banner-mozak.png" alt="" className="absolute top-0 left-0 z-10 object-cover object-center w-full h-full" />
                    <img src="./modal/logo-mozak-30anos.svg" alt="" className="relative z-20 object-contain object-center w-[460px] h-[460px]" />
                </header>

                <div className="flex flex-col gap-10 p-7 md:p-20">
                    <div className="flex flex-col gap-5">
                        <h3 className="text-[#212121] text-2xl md:text-4xl font-bold">A Mozak</h3>
                        <p className="text-[#212121] text-lg font-medium">O carioca é um estado de espírito. <br /> É levar a vida com leveza e espontaneidade. E saber que mar, natureza, dia e noite se misturam como um ritmo próprio, que só o Rio é capaz de gerar.</p>
                        <p className="text-[#212121] text-lg font-medium">A Mozak está presente há 30 anos no coração da zona sul. Com atendimento personalizado, unimos exclusividade, design autoral e arquitetura assinada, sempre pensando em quem busca o melhor da cidade.</p>

                        <span className="text-[#212121] text-lg font-bold">Sinta essa experiência. Viva a essência carioca.</span>
                    </div>

                    <div className="w-full">
                        <img src="./modal/DJI_0176-HDR.png" alt="" className="object-contain object-center w-full h-auto" />
                    </div>

                    <div className="w-full flex flex-col space-y-10">
                        <h5 className="text-[#212121] text-sm uppercase">NOSSO PROPÓSITO</h5>
                        <h3 className="text-[#212121] text-4xl">Levar a essência carioca para o conceito de viver, fazendo a diferença na vida das pessoas. </h3>

                        <div className="w-full grid md:grid-cols-2 gap-10">
                            <div className="flex flex-col gap-2">
                                <p className="text-[#212121] text-lg font-medium"><strong>Apaixonados pelo Rio</strong> <br />Fundada em 1994, a Mozak completa 30 anos de amor pelo Rio, mantendo o compromisso de construir imóveis exclusivos e sofisticados para melhorar o conceito de viver.</p>
                                <p className="text-[#212121] text-lg font-medium">A arquitetura é um convite a continuar a experiência de se viver no bairro, onde cores e aquarelas traduzem a leveza da essência carioca.</p>
                            </div>
                            <div className="flex flex-col">
                                <p className="text-[#212121] text-lg font-medium"><strong>Mozak Ateliê</strong><br />
                                    A sua unidade pode refletir ainda mais o seu estilo através do nosso serviço de personalização. Conte com a nossa curadoria e escolha as opções que melhor refletem a sua essência.</p>
                            </div>
                        </div>
                    </div>

                    <div className="w-full flex flex-col md:flex-row gap-10 md:gap-20 md:items-center">
                        <img src="./modal/RLFJ5193.png" alt="" className="object-contain object-center w-[447px] h-[678px]" />

                        <div className="w-full flex flex-col space-y-8">
                            <h5 className="text-[#212121] text-sm uppercase">NOSSA EQUIPE</h5>
                            <h3 className="text-[#212121] text-4xl font-bold">Um time exclusivo de especialistas Mozak</h3>
                            <p className="text-[#212121] text-lg">Conhecemos como ninguém as melhores regiões e bairros da Zona Sul do Rio. Nosso time de especialitas possui os conhecimentos  do mercado imobiliário de alto padrão e entende como ninguém as nuances comportamentais do carioca.</p>
                            <p className="text-[#212121] text-lg">Nossa curadoria e olhar atento para a arquitetura, arte e design nos levaram a desenvolver e criar as melhores oportunidades de investimento imobiliários na região, nos tornando a empresa de referência no mercado.</p>
                            <p className="text-[#212121] text-lg">Viva a experiência Mozak com quem entende o melhor do Rio.</p>

                            <a href="https://mozak.hypnobox.com.br/?controle=Atendimento&amp;acao=index&amp;produto=37" target="_blank" rel="noopener noreferrer" className="border border-[#212121] bg-transparent w-[284px] h-[50px] text-[#212121] text-lg flex items-center justify-center">Fale com especialista</a>
                        </div>
                    </div>
                </div>

                {/* <footer className="w-full h-auto md:h-[414px] bg-[#57614E] flex flex-col md:flex-row justify-between p-7 md:p-20">
                    <img src="https://acquamozak.com.br/wp-content/themes/mozak/assets/img/logo-mozak-30anos.svg" alt="" className="object-contain object-center w-[310px] h-[66px]" />

                    <div className="flex flex-col space-y-5 md:max-w-[470px]">
                        <h4 className="text-white text-2xl md:text-4xl font-bold">Vamos manter contato?</h4>
                        <p className="text-white text-lg">Inscreva-se e receba as novidades e lançamentos da Mozak em primeira mão.</p>

                        <form action="" className="flex flex-col gap-3">
                            <input type="text" placeholder="Nome *" className="w-full h-[52px] border border-white text-white bg-transparent p-4 placeholder:text-white" />
                            <div className="flex flex-row">
                                <input type="email" placeholder="Email *" className="w-full h-[52px] border border-white text-white bg-transparent p-4 placeholder:text-white" />
                                <button className="w-[122px] h-[52px] bg-white text-[#57614E] text-lg">Enviar</button>
                            </div>
                        </form>
                    </div>
                </footer> */}
            </div>
        </div>
    );
}

export default ModalMozak;