import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import 'swiper/css';
import 'swiper/css/navigation';
import gsap from 'gsap';
import { IoIosArrowDown } from "react-icons/io";

const schema = z.object({
    nome: z.string().min(1, 'Nome é obrigatório'),
    email: z.string().email('Email inválido').min(1, 'Email é obrigatório'),
    tel_celular: z.string().min(1, 'Telefone é obrigatório').min(14, 'Telefone Incompleto'),
    intereese: z.string().min(1, 'Campo obrigatório'),
    outro_interesse: z.string(),
    terms: z.boolean().refine(value => value === true, {
        message: 'Você precisa aceitar os termos'
    })
});

interface IPoliticas {
    setPoliticas: React.Dispatch<React.SetStateAction<boolean>>,
    politicas: boolean
}

function Contact({ setPoliticas, politicas }: IPoliticas) {
    const intereeses = [
        'Quero adquirir um imóvel em Jardim Pernambuco',
        'Atuo com arquitetura, arte e design e quero ser um parceiro Mozak',
        'Sou corretor/imobiliária e quero ser um parceiro Mozak',
        'Estou apenas curioso e gostaria de saber mais sobre o produto',
        'Outro interesse'
    ];
    const [open, setOpen] = useState(false);
    const [outro, setOutro] = useState(false)
    const [Submitting, setIsSubmitting] = useState('Enviar');
    const [successVar, setSuccessVar] = useState(false);

    const { register, handleSubmit, formState: { errors }, setValue, watch } = useForm({
        resolver: zodResolver(schema),
        defaultValues: {
            intereese: '',
            nome: '',
            email: '',
            tel_celular: '',
            terms: '',
            outro_interesse: '',
        }
    });

    const intereeseValue = watch('intereese');

    function success() {
        if (!successVar) {
            gsap.timeline({}).to('#obrigado', {
                y: '0'
            }).fromTo('#obrigado > div > *', {
                y: 20,
                opacity: 0
            }, {
                y: 0,
                opacity: 1,
                stagger: 0.2
            });
            setSuccessVar(true);
        } else {
            gsap.timeline({}).fromTo('#obrigado > div > *', {
                y: 0,
                opacity: 1
            }, {
                y: 20,
                opacity: 0,
                stagger: 0.2
            }).to('#obrigado', {
                y: '100%',
            });
            setSuccessVar(false);
        }
    }

    function mascaraTelefone(event: any) {
        let telefone = event.target.value.replace(/\D/g, ''); // Remove caracteres não numéricos

        if (telefone.length > 11) {
            telefone = telefone.slice(0, 11);
        }

        if (telefone.length <= 10) {
            event.target.value = telefone.replace(/(\d{2})(\d{4})(\d{0,4})/, '($1) $2-$3');
        } else {
            event.target.value = telefone.replace(/(\d{2})(\d{5})(\d{0,4})/, '($1) $2-$3');
        }
    }

    const onSubmit = async (data: any) => {
        console.log('Enviando dados para o servidor...');
        setIsSubmitting('Enviando...')
        if (outro) {
            const newData = {
                nome: data['nome'],
                email: data['email'],
                tel_celular: data['tel_celular'],
                question: data['outro_interesse']
            }
            try {
                const response = await fetch('/api/send_email.php', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(newData)
                });
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                const result = await response.json();
                console.log('Resposta do servidor:', result);
                setIsSubmitting('Enviado');
                success();
            } catch (error) {
                console.error('Erro ao enviar dados:', error);
            }
        } else {
            const newData = {
                nome: data['nome'],
                email: data['email'],
                tel_celular: data['tel_celular'],
                question: data['intereese']
            }
            try {
                const response = await fetch('/api/send_email.php', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(newData)
                });
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                const result = await response.json();
                console.log('Resposta do servidor:', result);
                setIsSubmitting('Enviado');
                success();
            } catch (error) {
                console.error('Erro ao enviar dados:', error);
            }
        }
    };

    return (
        <>
            <form className='w-full md:mb-0 p-5 lg:p-2 2xl:p-8 flex flex-col items-center' onSubmit={handleSubmit(onSubmit)}>
                <h1 className='text-white font-neue lg:text-lg font-semibold text-balance 2xl:text-xl text-center mb-8 lg:mb-8 2xl:mb-12'>Cadastre-se na lista de espera e receba informações exclusivas</h1>
                <div className='relative flex flex-col w-full mb-8 lg:mb-7 2xl:mb-8'>
                    <input id='nome' type='text' placeholder='Nome' className='font-neue text-white font-semibold peer bg-transparent focus:outline-none placeholder-transparent border-b-2 border-white' {...register('nome')} />
                    <label className='font-neue pointer-events-none text-white text-xs absolute left-0 -top-[50%] 
                        peer-placeholder-shown:top-0 transition-all duration-150 peer-focus:-top-[50%] 
                        peer-focus:text-xs peer-placeholder-shown:text-sm'>Nome</label>
                    {errors.nome && <p className='font-neue text-sm absolute left-0 bottom-[-20px] text-red-300'>{`${errors.nome.message}`}</p>}
                </div>
                <div className='relative flex flex-col w-full mb-8 lg:mb-7 2xl:mb-8'>
                    <input id='email' type='text' placeholder='Email' className='peer font-neue text-white font-semibold bg-transparent focus:outline-none placeholder-transparent border-b-2 border-white' {...register('email')} />
                    <label className='font-neue pointer-events-none text-white text-xs absolute left-0 -top-[50%] 
                        peer-placeholder-shown:top-0 transition-all duration-150 peer-focus:-top-[50%] 
                        peer-focus:text-xs peer-placeholder-shown:text-sm'>Email</label>
                    {errors.email && <p className='font-neue text-sm absolute left-0 bottom-[-20px] text-red-300'>{`${errors.email.message}`}</p>}
                </div>
                <div className='relative flex flex-col w-full mb-8 lg:mb-7 2xl:mb-8'>
                    <input id='tel_celular' type='text' placeholder='Telefone' className='peer font-neue text-white font-semibold bg-transparent focus:outline-none placeholder-transparent border-b-2 border-white' {...register('tel_celular')} onInput={mascaraTelefone} />
                    <label className='font-neue pointer-events-none text-white text-xs absolute left-0 -top-[50%] 
                        peer-placeholder-shown:top-0 transition-all duration-150 peer-focus:-top-[50%] 
                        peer-focus:text-xs peer-placeholder-shown:text-sm'>Telefone</label>
                    {errors.tel_celular && <p className='font-neue text-sm absolute left-0 bottom-[-20px] text-red-300'>{`${errors.tel_celular.message}`}</p>}
                </div>
                <div className='w-full mb-3 relative'>
                    <div className='w-full text-sm text-verde4 border-b-2 border-white relative'>
                        <div className='flex justify-between items-center'>
                            <input type="text" {...register('intereese')} readOnly onChange={()=>setOpen(false)} placeholder='Qual é seu interesse neste lançamento?' className='peer cursor-pointer w-full text-white font-semibold bg-transparent rounded-sm placeholder:pl-1 focus:outline-none placeholder:text-xs placeholder:text-transparent placeholder:transition-all placeholder:font-normal' onClick={() => {setOpen(!open); setValue('intereese', '')}} />
                            <label className={`font-neue pointer-events-none text-white absolute left-0 ${intereeseValue.length > 0 ? '-top-[70%] text-xs': 'top-0 text-sm'} transition-all duration-150`}>Qual é seu interesse neste lançamento?</label>
                            <IoIosArrowDown width={15} height={15} className={`${open ? 'rotate-180' : ''} w-[15px] h-[15px] transition-all text-white`} />
                        </div>
                        <div className={`grid grid-rows-0 transition-all ${open ? 'grid-rows-1' : ''} bg-white absolute rounded-md shadow-md top-5 z-10 w-[105%] 2xl:w-[105%] left-[-2.5%]`} onClick={() => setOpen(!open)}>
                            <div className='overflow-hidden'>
                                {intereeses.map((q) => (
                                    <div className='flex items-center' key={q}>
                                        {q !== intereeses[intereeses.length - 1] ?     
                                            <p className='text-sm border-b-[1px] border-verde4/25 w-full leading-4 py-2 px-2 cursor-pointer hover:bg-verde4 hover:text-white transition-all' onClick={() => { setValue('intereese', q); setOutro(false) }}>{q}</p>
                                            : <p className='text-sm border-b-[1px] border-verde4/25 w-full leading-4 py-2 px-2 cursor-pointer hover:bg-verde4 hover:text-white transition-all' onClick={() => { setValue('intereese', q); setOutro(true)}}>{q}</p>
                                        }
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    {errors.intereese && <p className='font-neue text-sm absolute left-0 bottom-[-20px] text-red-300'>{`${errors.intereese.message}`}</p>}
                </div>
                {
                    outro ?
                    <div className='relative flex flex-col w-full mb-8 lg:mb-7 2xl:mb-8 mt-2'>
                        <input id='outro_interesse' type='text' placeholder='Descreva seu interesse' className='peer text-sm font-neue placeholder-transparent text-white font-semibold bg-transparent focus:outline-none placeholder:font-normal placeholder:text-sm border-b-2 border-white' {...register('outro_interesse')}/>
                        <label className='font-neue pointer-events-none text-white/50 text-xs absolute left-0 -top-[50%] 
                        peer-placeholder-shown:top-0 transition-all duration-150 peer-focus:-top-[50%] 
                        peer-focus:text-xs peer-placeholder-shown:text-sm'>Descreva seu interesse</label>
                        {errors.outro_interesse && <p className='font-neue text-sm absolute left-0 bottom-[-20px] text-red-300'>{`${errors.outro_interesse.message}`}</p>}
                    </div>
                    : ''
                }
                <div className='flex items-center gap-2 relative mt-5 mb-8 lg:mb-8 2xl:mb-8'>
                    <input type='checkbox' {...register('terms')} />
                    <span className='text-white text-xs lg:text-[14px] font-neue'>Li e aceito os <span onClick={() => setPoliticas(!politicas)} className='cursor-pointer underline'>Termos de Uso e Política de Privacidade.</span></span>
                    {errors.terms && <p className='font-neue text-sm absolute left-0 bottom-[-20px] text-red-300'>{`${errors.terms.message}`}</p>}
                </div>
                <div className='flex gap-2 mt-0 relative'>
                    <button disabled={Submitting !== 'Enviar'}
                        className={`border-2 border-white bg-white text-verde1 px-5 py-1 rounded-sm mb-2 ${Submitting !== 'Enviar' ? 'opacity-45': 'hover:bg-verde1 hover:text-white hover:shadow-md duration-150'}`}>
                        {Submitting}
                    </button>
                </div>
            </form>
        </>
    );
}

export default Contact;
