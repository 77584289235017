import React, { useEffect, useState } from 'react';
import Contact from './Contact';
import Footer from './Footer';
import Politicas from './Politicas';
import { IoIosClose } from 'react-icons/io';
import ModalMozak from './ModalMozak';
import { useGSAP } from '@gsap/react';
import gsap from 'gsap';

function Content() {

    function scrollToMe() {
        document.getElementById('content')?.scrollIntoView({ behavior: 'smooth' })
    }

    const [politicas, setPoliticas] = useState(false)


    function createSpans(text: string) {
        return text.split('').map((char, index) => <span className='inline-block whitespace-pre' key={index}>{char}</span>)
    }

    useGSAP(() => {
        gsap.fromTo('#nameIMV > span', {
            y: 0,
        }, {
            keyframes: {
                y: [0, -10, 0]
            },
            delay: 3,
            repeatDelay: 3,
            stagger: .1,
        })
    })

    function success() {
        gsap.timeline({}).fromTo('#obrigado > div > *', {
            y: 0,
            opacity: 1
          }, {
            y: 20,
            opacity: 0,
            stagger: 0.2
          }).to('#obrigado', {
            y: '100%',
          })
    }

    return (
        <>
            <div id='content' onClick={scrollToMe} onMouseEnter={scrollToMe} className='z-20 h-screen md:h-auto w-full flex flex-col-reverse lg:flex-row-reverse items-center justify-around md:justify-between container xl:px-32 mx-auto'>
                <div id='form' className='w-11/12 mb-5 md:mb-0 lg:w-6/12 xl:w-5/12 bg-verde4/80 flex flex-col items-center shadow-forms rounded-2xl'>
                    <div className='flex flex-col items-center z-20 pt-5 px-8'>
                        <Contact setPoliticas={setPoliticas} politicas={politicas} />
                    </div>
                    <Footer />
                </div>
                <div id='text' className='mt-16 md:mt-0 text-white drop-shadow-md text-center flex flex-col items-center gap-3 lg:gap-3 2xl:gap-6'>
                    <h1 className='text-xl lg:text-2xl 2xl:text-3xl'>Em breve, um novo lançamento no</h1>
                    <h1 id='nameIMV' className='text-3xl lg:text-5xl 2xl:text-6xl font-bold'>{createSpans('Jardim Pernambuco')}</h1>
                    <h2 className='text-md lg:text-xl 2xl:text-2xl'>Poucas unidades, garantindo privacidade <br />e exclusividade privilegiada</h2>
                </div>
            </div>
            <div id='modal' data-modal='deactive' className='fixed data-[modal="deactive"]:translate-y-[100%] data-[modal="active"]:translate-y-[0%] transition-all duration-500 flex flex-col inset-0 items-center justify-center z-50'>
                <button className='w-11/12 flex justify-end z-20'><IoIosClose width={42} height={42} className='w-[42px] h-[42px] mr-[-10px] mt-[-22px] text-white' onClick={() => document.getElementById('modal')?.setAttribute('data-modal', 'deactive')} /></button>
                <div className='absolute inset-0 bg-black/25' onClick={() => document.getElementById('modal')?.setAttribute('data-modal', 'deactive')} ></div>
                <ModalMozak />
            </div>
            <section id='obrigado' className='bg-white z-50 fixed top-0 left-0 h-screen w-full flex flex-col justify-center items-center' style={{ transform: "translateY(100%)" }}>
                <div className='flex flex-col text-blue3 justify-center items-center'>
                <h1 className='text-3xl font-bold'>Obrigado!</h1>
                <p className='text-2xl'>Em breve entraremos em contato.</p>
                <div>
                    <button onClick={success} className='bg-verde4 text-white rounded-full py-1 px-2 mt-2 border-2 border-blue3 hover:bg-white hover:text-verde4 cursor-pointer duration-150'>Continuar navegando</button>
                </div>
                </div>
            </section>
            <Politicas setPoliticas={setPoliticas} politicas={politicas} />
        </>
    );
}

export default Content;